<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, setDoc } from "firebase/firestore";
//import firebase from "firebase/compat/app";
//import "firebase/compat/storage";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "New user",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "New User",
      adding_user: false,
      regimes: ["Vegana", "Vegetariana", "Senza glutine", "Senza lattosio"],
      regimeUser: [],
      items: [
        {
          text: "Contacts",
        },
        {
          text: "New User",
          active: true,
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    AddNewUser() {
      this.adding_user = true;

      var email = document.getElementById("email").value;
      var password = document.getElementById("password").value;

      var sexe = document.getElementById("sexe").value;
      var first_name = document.getElementById("first_name").value;
      var last_name = document.getElementById("last_name").value;
      var date_naissance = document.getElementById("date_naissance").value;
      var language = document.getElementById("language").value;
      var target = document.getElementById("target").value;
      var tall = document.getElementById("tall").value;

      var today = new Date().getTime();

      var profil = {
        affiche_nutrition: true,
        ajust_nutrition: 0,
        ban_chat: false,
        coach: null,
        besoin_kcal: 0,
        email: email,
        end_subscription_basic: null,
        end_subscription_premium: null,
        favoris_breakfast: [],
        favoris_meal: [],
        favoris_snack: [],
        first_weight_date: null,
        first_weight: null,
        last_evolution: null,
        last_login: null,
        last_ticket: null,
        last_training: null,
        level: 0,
        mail_paypal: null,
        mail_stripe: null,
        materiel: ["Bodyweight"],
        niveau: "Easy",
        note_user_coach: null,
        photo_profil: null,
        poids_actuel: null,
        role: [],
        total_kcal: 0,
        sexe: sexe,
        first_name: first_name,
        last_name: last_name,
        date_naissance: date_naissance,
        date_registration: today,
        locale: language,
        target: parseFloat(target),
        taille: parseInt(tall),
        regime: this.regimeUser,
        next_program_premium: today,
      };

      const auth = getAuth();
      const db = getFirestore();

      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          await setDoc(doc(db, "users", user.uid), profil);

          const functions = getFunctions();
          const regenerateMenu = httpsCallable(functions, "regenerateMenu");
          regenerateMenu({
            idUser: user.uid,
            regime: this.regimeUser,
          });
          this.$router.push({
            path: "/contacts/list"
          });
        })
        .catch((error) => {
          this.adding_user = false;
          console.log("Error: ", error);
        });

      /*firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (userCredential) => {
          const uid = userCredential.uid;
          var doc = await firebase.firestore().collection("users").doc(uid);
          doc.set(profil).then(async () => {
            console.log("user: " + doc.id);
            var regenerateMenu = firebase
              .functions()
              .httpsCallable("regenerateMenu");

            regenerateMenu({
              idUser: doc.id,
              regime: this.regimeUser,
            });
            this.$router.push({
              path: "/contacts/profile/" + doc.id,
            });
          });
        })
        .catch((error) => {
          this.adding_user = false;
          console.log("Error: ", error);
        });
    },*/
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="name@example.com"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Password</label>
              <input
                type="text"
                class="form-control"
                id="password"
                placeholder="*****"
              />
            </div>
            <hr />
            <div class="mb-3">
              <label class="form-label">Sexe</label>
              <select class="form-select" id="sexe" aria-label="Sexe">
                <option value="Female" selected>Female</option>
                <option value="Male">Male</option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">First Name</label>
              <input type="text" class="form-control" id="first_name" />
            </div>
            <div class="mb-3">
              <label class="form-label">Last Name</label>
              <input type="text" class="form-control" id="last_name" />
            </div>
            <div class="mb-3">
              <label class="form-label">Birthday</label>
              <input type="date" class="form-control" id="date_naissance" />
            </div>
            <div class="mb-3">
              <label class="form-label">Language</label>
              <select class="form-select" id="language" aria-label="Language">
                <option value="it" selected>IT</option>
                <option value="fr">FR</option>
                <option value="es">ES</option>
                <option value="us">US</option>
                <option value="br">BR</option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">Target (kg)</label>
              <input type="number" class="form-control" id="target" />
            </div>
            <div class="mb-3">
              <label class="form-label">Tall (cm)</label>
              <input type="number" class="form-control" id="tall" />
            </div>
            <div class="mb-3">
              <label class="form-label">Regime</label>
              <b-form-group>
                <b-form-checkbox-group
                  v-model="regimeUser"
                  :options="regimes"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
            <button type="button" class="btn btn-success" @click="AddNewUser()">
              <span
                v-if="adding_user"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Add New User
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
